import * as React from 'react';

//import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Grid } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Link from './Link';
import { Button } from '@material-ui/core';
import RssFeedIcon from '@material-ui/icons/RssFeed';

const useStyles = makeStyles((theme) => ({
  toolbar: {
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  toolbarTitle: {
    flex: 1,
  },
  toolbarSecondary: {
    justifyContent: 'space-between',
    overflowX: 'auto',
  },
  toolbarLink: {
    padding: theme.spacing(1),
    flexShrink: 0,
  },
  title: {
    height: "100px",
    justifyContent: "space-between",
    BackgroundColor: theme.palette.primary.main,
    alignItems: "center"
  },
  subscribe: {
    verticalAlign: 'middle',
    display: 'inline-flex',
    color: `${theme.palette.primary.main}`,
    textDecoration: `none`,
    '&:hover': {
      textDecoration: `underline`
    }
  },
}));

const Header = ({ title, categories, hideCategory }) => {
  const classes = useStyles()
  return (
    <React.Fragment>
      <Grid container className={classes.title}>
        <Grid item>
          <Link to={`/`}>
            <Typography
              component="h2"
              variant="h5"
              color="inherit"
              noWrap
              className={classes.toolbarTitle}
            >
              {title}
            </Typography>
          </Link>
        </Grid>
        <Grid item>
          <Link to={`/`}><Button variant="contained" color="secondary" size="small" fontSize="small">TOP</Button></Link>
          <Link to={`/blog`}><Button variant="contained" color="secondary" size="small" fontSize="small">全記事</Button></Link>
          <Link to={`/blog/access`}><Button variant="contained" color="secondary" size="small" fontSize="small">場所</Button></Link>
          <Link to={`/blog/facility`}><Button variant="contained" color="secondary" size="small" fontSize="small">機材</Button></Link>

          <a className={classes.subscribe} href={`/rss.xml`}><Button variant="contained" color="secondary" size="small" fontSize="small">RSS<RssFeedIcon fontSize="small" /></Button></a>
        </Grid>
      </Grid>
      {
        !hideCategory &&
        <Grid container>
          {categories.map((edge) => (
            <Grid item xs={6} sm={3} key={edge.node.categorySlug}>
              <Link className={classes.toolbarLink} to={`/${edge.node.categorySlug}`}>
                <div style={{ display: "flex", flexDirection: "column", textAlign: "center" }}>
                  <Typography component="span" variant="subtitle1">{edge.node.categoryTitle}</Typography>
                </div>
              </Link>
            </Grid>
          ))}
        </Grid>
      }
    </React.Fragment >
  );
}

// Header.propTypes = {
//   categories: PropTypes.arrayOf(
//     PropTypes.shape({
//       name: PropTypes.string.isRequired,
//       slug: PropTypes.string.isRequired,
//     }),
//   ).isRequired,
//   title: PropTypes.string.isRequired,
// };

export default Header
